import React from "react"
import styled from "@emotion/styled"
import { CardsContainer } from "../../components/commons/cards-container"
import HomeSection from "./section-container"
import ProductCard from "./product-card"

const ProductsSection = ({ data }) => {
  const options = {
    postType: "products",
    sectionTitle: "produtos",
    titleColor: "#FFFFFF",
    bgColor: "#D7C0CF",
    button: {
      color: "#FFFFFF",
      bkg: "transparent",
      btnText: "Ir para loja virtual",
      externalLink: true,
      link: "http://guipoulain.iluria.com/"
    },
  }

  return (
    <LastSection>
      <HomeSection options={options}>
          <CardsContainer>
            {data.products.map((product, i) => (
              <ProductCard key={i} post={product} />
            ))}
          </CardsContainer>
      </HomeSection>
    </LastSection>
  )
}

export default ProductsSection

const LastSection = styled.div`
  margin-bottom: -50px;
`
